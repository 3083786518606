import React, { forwardRef } from 'react';
import { Editor } from '@hugerte/hugerte-react';

export const HugeRTEWrapper = forwardRef(({ value, onChange, placeholder, height = 250, ...props }, ref) => (
  <Editor
    init={{
      toolbar: 'undo redo | styleselect | forecolor | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent | link image | code | bullist | paste pastetext',
      height,
      menubar: false,
      statusbar: false,
      plugins: ['lists', 'paste', 'link'],
      placeholder: placeholder || '',
      paste_block_drop: true,
      paste_as_text: true,
      outputFormat: 'html',
      forced_root_block: '',
    }}
    value={value}
    onEditorChange={value => onChange?.(value)}
    onKeyDown={event => props.onKeyDown?.(event)}
    ref={ref}
    {...props}
  />
))